import _ from 'lodash';
import store from 'src/store';
import { ApiUrl } from 'src/Framework/Common/ApiUrl';
import { APIHandler } from 'src/Framework/Communication/ServerProxy';

import { actionsTypes } from './PatientFormReducer';
import { IFormItem } from './types';

export const FORMS_PATIENTS = 'FORMS_PATIENTS';
export const FORM_LIST_SELECTED_ITEM = 'FORM_LIST_SELECTED_ITEM';
export const SET_SORT = 'SET_SORT';
export const SET_FILTERS = 'FORMS_LIST_SET_FILTERS';


export const setSelectedItem = (payload: any, subjectId: any) => {
  store.dispatch({
    type: FORM_LIST_SELECTED_ITEM,
    subjectId,
    payload
  });
};

export const setSort = (payload: any, subjectId: any) => {
  store.dispatch({
    type: SET_SORT,
    subjectId,
    payload
  });
};

export const setFilters = (payload: any, subjectId: any) => {
  store.dispatch({
    type: SET_FILTERS,
    subjectId,
    payload
  });
};

export const getTemplateForm = (search: string) => {
  return APIHandler.AxiosInstance.post(
    ApiUrl.GET_TEMPLATE_FORM,
    {
      filter: {
        searchString: search
      },
      startIndex: 0,
      count: 30,
      sortBy: '',
      sortType: null
    },
    { withCredentials: true }
  );
};

export const postTemplateFormRecord = async (
  {
    seedIds,
    patientId,
    clinicId
  }: {
    seedIds: number[],
    patientId: number,
    clinicId?: number
  }
) => {
  try {
    const current = store.getState().user.current
    const userId = current.userId
    const providerId = store.getState().user?.preferences?.userProviderId?.value;
    const response = await APIHandler.AxiosInstance.post(
      ApiUrl.GET_TEMPLATE_FORM_NEW,
      {
        seedIds,
        userId,
        providerId,
        patientId,
        clinicId: clinicId ? clinicId : current.clinicId
      },
      { withCredentials: true }
    );
    if (response.data.success) {
      return response.data.result.summary
    }
    return
  } catch (e) {
    console.log({ e })
    return
  }

};


export const newTemplateFormRecord = (
  seedIds: number[],
  userId: number,
  providerId: number,
  appoinmentId: number,
  patientId: number,
  saveRecords: boolean
) => {
  const clinicId = store.getState().user.current.clinicId
  return APIHandler.AxiosInstance.post(
    ApiUrl.NEW_TEMPLATE_FORM_RECORD,
    {
      // list of seed ids for forms you want to add new
      seedIds,
      // current user id
      userId,
      // current provider id for user
      providerId,
      // based on how user entered chart, if from schedule this should be based on the event they used to enter appointments
      appoinmentId,
      // subject id
      patientId,
      // default 6 is correct, send 6 on all screens for counseling
      //typeLoadByAnswerFlag: 6,
      //from the form list, this should be false, from the note's add form, this should be set to true
      //saveRecords,
      clinicId
    },
    { withCredentials: true }
  );
};

export const getFormPreview = (seedId: number) => {
  return APIHandler.AxiosInstance.post(
    `${ApiUrl.GET_TEMPLATE_FORM_NEW}/preview`,
    { seedId },
    { withCredentials: true }
  );
};

export const updatePatientForms = (
  payload: any,
  subjectId: string | number
) => {
  store.dispatch({
    type: actionsTypes.SET,
    payload,
    subjectId
  });
};

const setPatientForms = (payload: any, subjectId: string | number) => {
  store.dispatch({
    type: actionsTypes.SET,
    payload,
    subjectId
  });
};

export const setNewPatientForm = (payload: any, patientId: string | number) => {
  const list =
    store.getState().client.forms[patientId].forms.summary;
  list[payload.id] = payload
  setPatientForms(list, patientId)
};

let formLoading = false

export const getPatientForms = async (patientId: number | string) => {
  try {
    if (formLoading) {
      return
    }
    formLoading = true
    const response = await APIHandler.AxiosInstance.get(
      ApiUrl.GET_TEMPLATE_FORM_NEW,
      {
        withCredentials: true,
        params: {
          patientId,
          IsOnlyLatestForm: true
        }
      }
    );
    if (response.data.success) {
      const data: any = response.data.result.summary;
      setPatientForms(data, patientId);
      const formListSelectedItem =
        store.getState().client.forms[patientId].formListSelectedItem;
      if (!formListSelectedItem) {
        const list: any = Object.values(data);
        const sorted = _.sortBy(
          list.filter((v: any) => v.isActive && v.status !== 'invalid'),
          'id'
        );
        const firstSeedId = sorted.reverse()[0]?.seedId;
        setSelectedItem(firstSeedId, patientId);
      }
      return data;
    }
  } catch (error) {
    console.log({ error });
    return;
  } finally {
    formLoading = false
  }
};

export const setIncludeSoapNoteForms = (payload: any, subjectId: number | string) => {
  store.dispatch({
    type: actionsTypes.SET_INCLUDE_SOAP_NOTE_FORMS,
    payload,
    subjectId
  });
}

const onChangeIncluteSoapNoteForms = (payload: any[], patientId: number | string) => {
  const summary = store.getState().client.forms[patientId].forms.IncludeSoapNoteForms;
  payload.forEach((v) => {
    summary[v.id] = { ...v };
  })
  setIncludeSoapNoteForms(summary, patientId);
}

export const getIncludeSoapNoteForms = async (patientId: number | string) => {
  try {
    const response = await APIHandler.AxiosInstance.get(
      ApiUrl.GET_TEMPLATE_FORM_NEW,
      {
        withCredentials: true,
        params: {
          patientId,
          IsOnlyLatestForm: true,
          IncludeSoapNoteForms: true
        }
      }
    );
    if (response.data.success) {
      const data: any = response.data.result.summary;
      setIncludeSoapNoteForms(data, patientId);
      return data;
    }
  } catch (error) {
    console.log({ error });
    return;
  } finally {
  }
};

export const onChangeItems = (payload: any[], patientId: number | string) => {
  const summary = store.getState().client.forms[patientId]?.forms.summary;
  if (summary) {
    payload.forEach((v) => {
      summary[v.id] = { ...v };
    })
    setPatientForms(summary, patientId);
  }
};

export const postPatientFormsSign = async (
  body: any,
  patientId: number | string
) => {
  try {
    const response = await APIHandler.AxiosInstance.post(
      `${ApiUrl.GET_TEMPLATE_FORM_NEW}/${body.seedId}/sign`,
      body,
      {
        withCredentials: true,
        interceptError: true
      }
    );
    if (response.data.success) {
      const summary = response.data.result.summary
      const data = Object.values(summary) as IFormItem[];
      const soapNoteForms = data.filter((v) => v.noteId)
      const patientForms = data.filter((v) => !v.noteId)
      if (soapNoteForms.length !== 0) {
        onChangeIncluteSoapNoteForms(soapNoteForms, patientId)
      }
      if (patientForms.length !== 0) {
        onChangeItems(data, patientId)
      }
      return data;
    }
    return;
  } catch (error) {
    console.log({ error });
    return;
  }
};

export const addFormHandler = async (e: any, subjectId: number) => {
  const seedIds = [e.seedId];
  const patientId = +subjectId;
  const res = await postTemplateFormRecord({
    patientId,
    seedIds
  });
  if (res) {
    const value: any = Object.values(res)[0];
    if (value) {
      setNewPatientForm(value, subjectId);
      return value
    }
  }
};

export const patchPatientForms = async (
  body: any,
  patientId: number | string
) => {
  try {
    const response = await APIHandler.AxiosInstance.patch(
      `${ApiUrl.GET_TEMPLATE_FORM_NEW}/${body.seedId}`,
      body,
      {
        withCredentials: true,
        interceptError: true
      }
    );
    if (response.data.success) {
      const summary = response.data.result.summary
      const data = Object.values(summary) as IFormItem[];
      const soapNoteForms = data.filter((v) => v.noteId)
      const patientForms = data.filter((v) => !v.noteId)
      if (soapNoteForms.length !== 0) {
        onChangeIncluteSoapNoteForms(soapNoteForms, patientId)
      }
      if (patientForms.length !== 0) {
        onChangeItems(data, patientId)
      }
      return data;
    }
    return;
  } catch (error) {
    console.log({ error });
    return;
  }
};
