import React, { FC, useEffect, useRef, useState } from 'react';
import { Row, Col } from 'antd';
import classNames from 'classnames';
import Tooltip from 'src/Framework/Controls/Tooltip';
import { IColumn, IDragAndDrop, ISort } from './types';

import { minColWidth } from './index';
import {
  AccessibilityMove,
  onKeyDownAccessibility,
  onKeyDownCallbacks
} from 'src/Framework/util/accessibility';

import FilterIcon from './FilterIcon';

import { HeaderContainer, HeaderContainerAbsolute, HideSpace } from './styled';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';

export interface IHeaderProps {
  columns: Array<IColumn>;
  headerBackground?: string;
  sort?: ISort;
  dragMarginLeft?: boolean;
  dragAndDropProps?: IDragAndDrop;
  horizontalItemPadding?: number;
  verticalItemPadding?: number;
  fontColor?: string;
  sortArrowIcon?: string;
  uniqueIdTable?: string;
}

interface IProps extends IHeaderProps {
  uniqueIdHeader?: string;
  onSort?: (sortBy: string) => any;
  enableEdit?: boolean;
  paddings?: string;
}

const Component: FC<IProps> = (props: IProps) => {
  const {
    uniqueIdHeader,
    headerBackground,
    sort,
    dragMarginLeft,
    dragAndDropProps,
    horizontalItemPadding,
    verticalItemPadding,
    fontColor,
    sortArrowIcon,
    onSort,
    enableEdit = true,
    paddings,
    uniqueIdTable
  } = props;
  const resizeRef = useRef<ResizeObserver>();
  const [height, setHeight] = useState(1);
  const rowRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const e = rowRef.current;
    if (e) {
      const check = () => {
        if (e.scrollHeight) {
          setHeight(e.scrollHeight);
        }
      };
      check();
      if (resizeRef.current) {
        resizeRef.current?.unobserve(e);
        resizeRef.current?.disconnect();
      }
      resizeRef.current = new ResizeObserver((entries) => {
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) {
            return;
          }
          check();
        });
      });
      resizeRef.current?.observe(e);
      return () => {
        resizeRef.current?.unobserve(e);
        resizeRef.current?.disconnect();
      };
    }
    return () => {};
  }, []);

  const columns = [...props.columns];
  if (!columns.some((v) => v.flex === 'auto')) {
    columns.push({
      key: '',
      flex: 'auto',
      title: ''
    });
  }
  return (
    <HeaderContainer style={{ height }}>
      <HeaderContainerAbsolute
        className={classNames('header-row')}
        id={uniqueIdHeader}
        style={{
          backgroundColor: headerBackground,
          height
        }}
      >
        <Row
          ref={rowRef}
          style={{
            flexWrap: 'nowrap',
            padding: paddings ?? '',
            // for body scroll
            width: `100%`
          }}
          tabIndex={0}
          role="row"
          aria-label={'Table-header'}
          onKeyDown={(e) =>
            onKeyDownCallbacks(e, {
              ArrowDown: () => {
                if (uniqueIdTable) {
                  const element = document.getElementById(uniqueIdTable);
                  const firstElement = element?.children[0]?.children[0];
                  if (firstElement) {
                    //@ts-ignore
                    firstElement.focus();
                  }
                }
              }
            })
          }
        >
          {columns.map((value, index: number) => {
            const {
              span,
              title,
              sortBy,
              centered,
              flex,
              style,
              tooltipText,
              filterEnabled,
              filterContent,
              headerEllipsis,
              minWidth
            } = value;
            const colProps: any = {};
            if (flex) {
              colProps.flex = flex;
            } else {
              colProps.span = span;
            }
            if ((title === 'Edit' || title === 'Preview') && !enableEdit) {
              return null;
            }

            const sortActive =
              sortBy &&
              sort &&
              sort.sortBy &&
              sort.sortBy === sortBy &&
              sort.sortType !== null;
            const titleStyle = {
              overflowX: headerEllipsis ? ('hidden' as const) : undefined,
              textOverflow: headerEllipsis ? ('ellipsis' as const) : undefined,
              color:
                filterEnabled || sortActive
                  ? globalStyleVariables.newBrandingPrimary200
                  : undefined
            };

            const colStyles: React.CSSProperties = {
              paddingLeft: horizontalItemPadding,
              paddingRight: horizontalItemPadding,
              paddingTop: verticalItemPadding,
              paddingBottom: verticalItemPadding,
              justifyContent: centered ? 'center' : 'flex-start',
              color: fontColor,
              minWidth:
                flex && flex === 'auto' ? minWidth || minColWidth : 'auto',
              ...style
            };
            if (value.sticky) {
              colStyles.background = headerBackground;
              colStyles.position = 'sticky';
              colStyles.zIndex = 1;
              if (value.sticky.position === 'right') {
                colStyles.right = 0;
                colStyles.borderLeft = `1px solid ${globalStyleVariables.borderColor}`;
              }
              if (value.sticky.position === 'left') {
                colStyles.left = 0;
                colStyles.borderRight = `1px solid ${globalStyleVariables.borderColor}`;
              }
            }
            return (
              <Col
                tabIndex={0}
                role="columnheader"
                aria-colindex={index + 1}
                onKeyDown={(e) =>
                  onKeyDownAccessibility(e, undefined, AccessibilityMove.ROW)
                }
                key={index}
                {...colProps}
                className={classNames({
                  'header-item': true,
                  sortBy: sortBy,
                  dragMarginLeft:
                    index === 0 && dragMarginLeft && dragAndDropProps
                })}
                style={colStyles}
                onClick={() => {
                  if (sortBy && onSort) {
                    onSort(sortBy || '');
                  }
                }}
              >
                {tooltipText ? (
                  <Tooltip placement="top" title={tooltipText}>
                    <span style={titleStyle}>{title}</span>
                  </Tooltip>
                ) : (
                  <span style={titleStyle}>{title}</span>
                )}
                <div
                  className="color-container"
                  style={{ color: titleStyle.color }}
                >
                  {!!sortBy && (
                    <div
                      className={classNames('sort-icon-container', {
                        active: sortActive
                      })}
                    >
                      <div
                        className={classNames({
                          sortIcon: true,
                          up: sort?.sortType === 'asc'
                        })}
                        style={{
                          WebkitMaskImage: `url(${sortArrowIcon})`,
                          maskImage: `url(${sortArrowIcon})`
                        }}
                      />
                    </div>
                  )}
                  {filterContent && <FilterIcon {...value} />}
                </div>
              </Col>
            );
          })}
        </Row>
      </HeaderContainerAbsolute>
      <HideSpace
        style={{
          backgroundColor: headerBackground,
          height
        }}
      />
    </HeaderContainer>
  );
};

Component.defaultProps = {
  horizontalItemPadding: 24,
  verticalItemPadding: 12,
  sortArrowIcon: require('src/Framework/Common/Svg/sort-icon.svg').default,
  headerBackground: '#F7F7F7',
  fontColor: 'black'
};

export default React.memo(Component);
