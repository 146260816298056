import React, { useEffect, useRef, useState } from 'react';
import { Row, Col } from 'antd';
import _ from 'lodash';
import cn from 'classnames';
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot
} from 'react-beautiful-dnd';

import ButtonIcon, { ICONS_LIST } from 'src/Framework/Controls/ButtonIcon';
import EllipsisText from 'src/Framework/Controls/EllipsisText';
import Tooltip from 'src/Framework/Controls/Tooltip';
import {
  AccessibilityMove,
  onKeyDownAccessibility,
  onKeyDownCallbacks,
  onNextRootSibling
} from 'src/Framework/util/accessibility';

import { IProps as IRootProps, ITableRenderItemData } from './types';

import { minColWidth } from './index';
import globalStyleVariables from 'src/Framework/Styles/variables.module.scss';
import Batch from './Batch';
import MouseOver from './MouseOver';
// import { MemoChangedLogger } from 'src/Framework/util/helps';

interface IProps<T> extends Omit<IRootProps<T>, 'data'> {
  index: number;
  value: ITableRenderItemData<T>;
  opened?: boolean;
  hightlighted?: boolean;
  selected?: boolean;
  lastItem: boolean;
  containerRef?: React.RefObject<HTMLDivElement>;
}

export type IPropsType<T> = IProps<T>;

export const DraggableItemComponent = <T,>(props: IPropsType<T>) => {
  const { value, index, disableDragAndDrop, dndDisabledTooltip } = props;
  const id = `${value.id}`;
  return (
    <Draggable
      key={id}
      draggableId={id}
      index={index}
      isDragDisabled={disableDragAndDrop}
    >
      {(
        dragProvided: DraggableProvided,
        dragSnapshot: DraggableStateSnapshot
      ) => (
        <div
          ref={dragProvided.innerRef}
          style={dragProvided.draggableProps.style}
          className={cn({
            'table-row-item-drag': true,
            isDragging: dragSnapshot.isDragging
          })}
          data-testid={id}
          data-index={index}
          {...dragProvided.draggableProps}
        >
          <div
            data-is-dragging={dragSnapshot.isDragging}
            {...dragProvided.dragHandleProps}
          >
            <Tooltip
              id=""
              title={disableDragAndDrop ? dndDisabledTooltip : undefined}
            >
              <div
                className="drag-icon"
                style={{
                  pointerEvents: value.hideOrderButtons ? 'none' : 'unset',
                  visibility: value.hideOrderButtons ? 'hidden' : 'visible',
                  WebkitMaskImage: `url(${ICONS_LIST.dragControl})`,
                  maskImage: `url(${ICONS_LIST.dragControl})`
                }}
              />
            </Tooltip>
          </div>
          <div className="render-row-item">
            <Component {...props} />
          </div>
        </div>
      )}
    </Draggable>
  );
};

export const DraggableItem = React.memo(DraggableItemComponent);

const Component = <T,>(props: IPropsType<T>) => {
  const {
    onChangeOrder,
    onClickRow,
    columnClassName,
    value,
    index,
    horizontalItemPadding,
    verticalItemPadding,
    fontColor,
    rowClassname,
    openedRowClassname,
    openedColumnClassname,
    opened,
    hightlighted,
    selected,
    containerId,
    dynamicClassNameRow,
    disableVerticalPadding,
    rowHover,
    rowIdFieldname,
    lastItem,
    onKeyDownRow,
    containerRef,
    disableDragAndDrop,
    dndDisabledTooltip
  } = props;
  const [pushUpdate, setPushUpdate] = useState(false);

  const rowRef = useRef<HTMLDivElement>(null);
  const mount = useRef(false);
  useEffect(() => {
    mount.current = true;
    if (value.onRowMount) {
      value.onRowMount(value);
    }
    return () => {
      if (value.onRowUnMount) {
        value.onRowUnMount(value);
      }
    };
  }, []);
  useEffect(() => {
    if (mount.current) {
      if (value.tempPushTimestampField) {
        const timestamp = new Date().getTime();
        if (timestamp - value.tempPushTimestampField < 1000) {
          setPushUpdate(true);
          setTimeout(() => {
            setPushUpdate(false);
          }, 2000);
        }
      }
    }
  }, [value]);

  if (value.batchSettings) {
    return <Batch<T> {...props} />;
  }
  const rowClassnames = {
    'on-click': onClickRow,
    [`${rowClassname}`]: rowClassname,
    [openedRowClassname || 'opened']: opened || selected
  };
  if (dynamicClassNameRow) {
    rowClassnames[dynamicClassNameRow.className] =
      dynamicClassNameRow.onEnabled(value as any, index);
  }
  const columns = [...props.columns];
  if (!columns.some((v) => v.flex === 'auto')) {
    columns.push({
      key: '',
      flex: 'auto',
      title: ''
    });
  }
  return (
    <>
      <Row
        ref={rowRef}
        role="row"
        aria-rowindex={index + 1}
        onKeyDown={(e) => {
          if (!(e.ctrlKey || e.key === 'Tab')) {
            e.stopPropagation();
          }
          if (e.code === 'Escape') {
            containerRef?.current?.focus();
          }
          onKeyDownAccessibility(e, undefined, AccessibilityMove.COL);
          if (onKeyDownRow) {
            onKeyDownRow(e);
          }
        }}
        id={`${containerId || ''}-row-${value[rowIdFieldname || 'id']}`}
        tabIndex={0}
        className={cn(rowClassnames, 'table-row-item', {
          'row-hovering': rowHover,
          'updated-by-push': pushUpdate
        })}
        onClick={() => {
          if (onClickRow) {
            onClickRow(index, value as any);
          }
        }}
        style={{
          flex: 1,
          flexWrap: 'nowrap',
          minWidth: '100%'
        }}
      >
        {columns!.map((val, i) => {
          const colProps: any = {};
          if (val.flex) {
            colProps.flex = val.flex;
          } else {
            colProps.span = val.span;
          }
          const columnClassNames = {
            'data-column-item': true,
            [openedColumnClassname || 'opened']: opened,
            hightlighted: hightlighted
          };
          if (columnClassName) {
            columnClassNames[columnClassName(value as any, index, i)] = true;
          }
          let style: React.CSSProperties = {
            paddingLeft: horizontalItemPadding,
            paddingRight: horizontalItemPadding,
            justifyContent: val.centered
              ? 'center'
              : val.justify
              ? val.justify
              : 'flex-start',
            color: fontColor,
            minWidth:
              val.flex && val.flex === 'auto'
                ? val.minWidth || minColWidth
                : 'auto'
          };
          if (!disableVerticalPadding) {
            style = {
              ...style,
              paddingTop: verticalItemPadding,
              paddingBottom: verticalItemPadding
            };
          }
          if (val.sticky) {
            style.position = 'sticky';
            style.zIndex = 1;
            if (val.sticky.position === 'right') {
              style.right = 0;
              style.borderLeft = `1px solid ${globalStyleVariables.borderColor}`;
            }
            if (val.sticky.position === 'left') {
              style.left = 0;
              style.borderRight = `1px solid ${globalStyleVariables.borderColor}`;
            }
          }
          return (
            <Col
              role="cell"
              aria-colindex={i + 1}
              key={`row-${index}-column-${i}`}
              {...colProps}
              style={style}
              className={cn(columnClassNames)}
              tabIndex={i === 0 ? 0 : -1}
              onKeyDown={(e) => {
                // e.stopPropagation();
                if (e.code === 'Escape') {
                  rowRef.current?.focus();
                }
                onKeyDownAccessibility(e, undefined, AccessibilityMove.ROW);
                onKeyDownCallbacks(e, {
                  ArrowDown: () => {
                    onNextRootSibling();
                  },
                  ArrowUp: () => {
                    onNextRootSibling();
                  }
                });
              }}
            >
              {val.type === 'change-order' && !value.hideOrderButtons ? (
                <Row>
                  <div
                    className={cn({
                      'order-button': true,
                      active: index > 0
                    })}
                  >
                    <ButtonIcon
                      disabled={disableDragAndDrop || index === 0}
                      isButton={true}
                      label="increase the order"
                      onClick={() => {
                        onChangeOrder && onChangeOrder(index, index - 1);
                        if (rowRef.current) {
                          //@ts-ignore
                          rowRef.current.focus();
                        }
                      }}
                      containerProps={{
                        style: { margin: '0 10px 0 0' }
                      }}
                      name={ICONS_LIST.arrowUp}
                      changeColorOnHover={true}
                      tooltip={
                        disableDragAndDrop ? dndDisabledTooltip : undefined
                      }
                      tooltipProps={{
                        placement: 'left'
                      }}
                    />
                  </div>
                  <div
                    className={cn({
                      'order-button': true,
                      active: lastItem
                    })}
                  >
                    <ButtonIcon
                      disabled={disableDragAndDrop}
                      isButton={true}
                      label="reduce the order"
                      onClick={() => {
                        onChangeOrder && onChangeOrder(index, index + 1);
                        if (rowRef.current) {
                          //@ts-ignore
                          rowRef.current.focus();
                        }
                      }}
                      name={ICONS_LIST.arrowUp}
                      changeColorOnHover={true}
                      iconStyle={{ transform: 'rotate(180deg)' }}
                      tooltip={
                        disableDragAndDrop ? dndDisabledTooltip : undefined
                      }
                      tooltipProps={{
                        placement: 'left'
                      }}
                    />
                  </div>
                </Row>
              ) : val.ellipsis ? (
                <EllipsisText
                  maxWidth={
                    val.flex && val.flex !== 'auto' ? val.flex : undefined
                  }
                >
                  {value[val.key]}
                </EllipsisText>
              ) : (
                value[val.key]
              )}
            </Col>
          );
        })}
      </Row>
      {value.mouseOverRow && (
        <MouseOver index={index} mouseOverData={value.mouseOverRow} />
      )}

      {opened ? value.underRowComponent : null}
    </>
  );
};
// For perfomance testing
// export default MemoChangedLogger<any>(Component)

const typedMemo: <T extends React.ComponentType<any>>(
  c: T,
  areEqual?: (
    prev: React.ComponentProps<T>,
    next: React.ComponentProps<T>
  ) => boolean
) => T = React.memo;

export default typedMemo(Component, (props, nextProps) => {
  // For performance, we can check memoize property changed or not
  // We can't check all value because inside value we can use Components which we can't compare
  // If we have same object as we had previous we don't need to rerender this row
  if (props.value.memoizeBy) {
    const memoizedChecker =
      _.isEqual(props.value.memoizeBy, nextProps.value.memoizeBy) &&
      props.opened === nextProps.opened &&
      props.selected === nextProps.selected &&
      props.hightlighted === nextProps.hightlighted;
    return memoizedChecker;
  }
  return false;
});
