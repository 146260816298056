import { upperFirst } from 'lodash';
import Logger from '../Logger';
import SubscriptionClass from './SubscriptionClass';
import { IListenerCallback, OnReconnecionStatusChanged } from './types';

// Generic Types buckets in separate logic
// Check logic GroupTypes in Admin panel
export enum BucketsList {
  PortalProviderMessaging = 'portalProviderMessaging',
  LetterField = 'letterField',
  LetterTemplate = 'letterTemplate',
  RcopiaNotificationCount = 'rcopiaNotificationCount',
  NoteRisk = 'noteRisk',
  Inventory = 'inventory',
  PatientCustomFieldValue = 'patientCustomFieldValue',
  PatientCustomField = 'patientCustomField',
  ReqOrder = 'reqOrder',
  ticketDetail = 'ticketDetail',
  typeMessage = 'typeMessage',
  txnCode = 'txnCode',
  typeCheckInStatus = 'typeCheckInStatus',
  groupDiagnosisClinicLink = 'groupDiagnosisClinicLink',
  groupTxnClinicLink = 'groupTxnClinicLink',
  patientAllergy = 'patientAllergy',
  ticketDetailHipaa = 'ticketDetailHipaa',
  selfCheckin = 'selfCheckin',
  patientProviderAssignment = 'patientProviderAssignment',
  providerActuals = 'actual',
  M1Note = 'm1Note',
  ticketDiagnosis = 'ticketDiagnosis',
  patient = 'patient',
  provider = 'provider',
  patientAssessment = 'patientAssessment',
  providerEvaluation = 'providerEvaluation',
  providerTypes = 'providerTypes',
  providerConsent = 'providerConsent',
  providerContract = 'providerContract',
  providerDocument = 'providerDocument',
  traineeGoal = 'traineeGoal',
  providerAgreement = 'providerAgreement',
  providerAssessment = 'providerAssessment',
  providerSupervisorAssignment = 'providerSupervisorAssignment',
  supervisorRole = 'supervisorRole',
  m1FormRecord = 'm1FormRecord',
  m1FormRecordSubmission = 'm1FormRecordSubmission',
  trainee = 'trainee',
  traineeContact = 'traineeContact',
  summaryReviewLog = 'summaryReviewLog',
  ProviderAlias = 'providerAlias',
  ProviderOutOfOffice = 'providerOutOfOffice',
  transactionSet = 'transactionSet',
  reqResultStatus = 'reqResultStatus',
  MedicatUser = 'medicatUser',
  PortalClinicAddress = "portalClinicAddress",
  PortalLocation = "portalLocation",
  PortalSymptom = "portalSymptom",
  PortalLocationClinicProvider = 'portalLocationClinicProvider',
  PortalSymptomReasons = 'portalSymptomReasons',
  PortalAppointmentReasonProviderLink = 'portalAppointmentReasonProviderLink',
  ClinicProvider = 'clinicProvider',
  ClinicProviderAlias = 'clinicProviderAlias'
}

export enum OnList {
  PushUpdate = 'PushUpdate'
}

type InstanceType = SubscriptionClass<typeof BucketsList, typeof OnList>;

export type ISubscriptionCallBack<T = any> = IListenerCallback<BucketsList, T>;

export const generateSubscriptionFilter = (
  bucketItem: BucketsList,
  ids: (string | number)[],
  key: string,
  isString?: boolean
): string => {
  return ids
    .map(
      (id) => `${bucketItem}.${upperFirst(key)} == ${isString ? `"${id}"` : id}`
    )
    .join(' || ');
};

const logger = new Logger({
  key: 'pushSubscription',
  subscribeByDefault: process.env.NODE_ENV === 'development'
});

class ApiSubscription {
  static instance: InstanceType;
  init = (
    {
      access_token,
      refresh_token,
      tenantId,
      signalRUrl
    }: {
      access_token: string;
      tenantId: string;
      refresh_token: string;
      signalRUrl: string;
    },
    connectedCallback?: () => any,
    onReconnecionStatusChanged?: OnReconnecionStatusChanged
  ) => {
    try {
      if (ApiSubscription.instance) {
        logger.log(
          'ApiSubscription instance already initialized',
          null,
          'error'
        );
        return;
      }
      ApiSubscription.instance = new SubscriptionClass({
        signalRUrl,
        access_token,
        refresh_token,
        tenantId,
        BUCKETS_ENUM: BucketsList,
        ON_ENUM: OnList,
        onConnected: connectedCallback,
        onReconnecionStatusChanged,
        logger
      });
    } catch (e) {
      console.log({ e });
    }
  };
}

export default ApiSubscription;
