export enum BroudcastChannelNames {
    MainTabClosed = 'MainTabClosed'
}

class BroadcastSubscription<Type> {
    channel: BroadcastChannel | null = null
    connection = ({
        channel,
        onmessage
    }: {
        channel: BroudcastChannelNames
        onmessage: any
    }) => {
        const bc = new BroadcastChannel(channel);
        this.channel = bc
        bc.onmessage = onmessage
    }
    postMessage = ({ type, message }: { type: Type, message: string }) => {
        this.channel?.postMessage({ type, message })
    }
    close = () => {
        this.channel?.close();
    }
}

export { BroadcastSubscription }